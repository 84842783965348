import React from 'react';
import Layout from '../../components/Layout';

import StandardsSearch from "../../components/standards/StandardsSearch";

export default function Index() {

    return (
        <Layout allowAnonymous={false}>
            <StandardsSearch style={{minWidth:700, width:"75%", marginLeft: "auto", marginRight: "auto"}}/>
        </Layout>
    )
}