//source: https://material-ui.com/components/tables/
import React, {useEffect} from 'react'
import {TableRow, TablePagination, TableHead, TableBody, Table, TableFooter, Checkbox, TableCell, IconButton} from "@material-ui/core";
import StandardPreview from "./StandardPreview";
import { StandardDto } from "../../api/StandardApi";

import TableHeaderCellStyled from "../common/TableHeaderCellStyled";

import { useStyles } from "../../styles/TableStyles";
import { useTranslation } from 'react-i18next';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

interface StandardTableProps{
    rows: StandardDto[],
    rowsPerPage:any,
    page:any,
    handleChangeRowsPerPage: any,
    handleChangePage: any,
    count?: any,
    style?:any,
    showInternalCodes?:boolean,
    //IF USE CHECBOXES, ROWS MUST HAVE ATTRIBUTE included
    showCheckboxes?:boolean,
    linkInNewWindow?:boolean,
    showUrl?:boolean
}

export default function StandardsTable(props: StandardTableProps) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();


    const columns = [
        { key: 'code', label: t("STANDARDCODE"), width: "15%" },
        { key: 'name', label: t("NAME"), width: "40%" },
    ];
    props.showInternalCodes==true && columns.push({ key: 'internalCodes', label: t("INTERNALCODES"), width: "40%"})
    columns.push({ key: 'harmonized', label: t("HARMONIZED"), width: "5%"})
    props.showUrl==true && columns.push({ key: 'url', label: t("URLINFO"), width: "10%"})
    props.showCheckboxes==true && columns.push({ key: 'included', label: t("INCLUDESTANDARD"), width: "5%"})

    return (
        <Table style={props.style} aria-label="sticky table">
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableHeaderCellStyled
                            key={column.key}
                            width={column.width}
                            label={column.label}
                            border={true}
                        />
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.rows.map((row) => {
                    return (
                        <StandardPreview key={row.id} id={row.id} standard={row.code} name={(i18n.language=="en" && (row.englishName!="" && row.englishName!=null)) ? row.englishName: row.name} url={row.url} harmonized={row.harmonized} internalCodes={props.showInternalCodes==true ? row.internalCodes : null} showUrl={props.showUrl} included={props.showCheckboxes==true ? row.included : null} handleIncluded={(value)=>{row.included=value}} linkInNewWindow={props.linkInNewWindow}/>
                        );
                })}
            </TableBody>
            <TableFooter>
                {(props.count>5 || props.rows.length>5) && 
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20, 30, (props.count<1000 ? { label: t("ALL"), value: 1000 } : 100)]}
                            count={props.count!=null ? props.count : props.rows.length}
                            rowsPerPage={props.rowsPerPage}
                            page={props.page}
                            SelectProps={{
                                inputProps: { 'aria-label': t("ROWSPERPAGE") },
                                native: true,
                            }}
                            labelRowsPerPage={t("ROWSPERPAGE")}
                            labelDisplayedRows={({ from, to, count }) =>from + '-' + to + t("ROWSOF") + count}
                            onPageChange={props.handleChangePage}
                            onRowsPerPageChange={props.handleChangeRowsPerPage}
                        />
                    </TableRow>
                }
            </TableFooter>
        </Table>

    );
}