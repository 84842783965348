import React, {useEffect, useState} from 'react'
import {Paper, CircularProgress} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { StandardDto ,findAllStandards, findAllInternalCodes, countStandards } from "../../api/StandardApi";
import StandardsTable from "./StandardsTable";
import {navigate} from "gatsby";

import SearchStyled from "../common/SearchStyled";
import ButtonStyled from "../common/ButtonStyled";

import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';
import AutocompleteStyled from "../common/AutocompleteStyled";

interface StandardsSearchProps{
    productId?: number,
    style?:any,
}

export default function StandardsSearch(props: StandardsSearchProps ){
    const classes = useStyles();
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true)
    const [rows, setRows] = useState<StandardDto[]>([]);
    const [rowsPerPageLimit, setRowsPerPageLimit] = useState<number>(10);
    const [currentPageOffset, setCurrentPageOffset] = useState<number>(0);
    const [totalStandardsCount, setTotalStandardsCount] = useState<number>(0);
    const [internalCodesFull, setInternalCodesFull] = useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [icodesFilter, setIcodesFilter] = React.useState([]);

    const getCurrentRows = (rowsPerPage, currentPage, nameContaining, icodes) => {
        findAllStandards(2, rowsPerPage, currentPage*rowsPerPage, null, null, nameContaining).then(data => {
            countStandards(nameContaining, null, null).then(countData => {
                setTotalStandardsCount(countData)
                /*data.forEach(row => {
                    let tmp = []
                    row.internalCodes.forEach(code => {
                        tmp.push(icodes.find(icode => icode.id == code).name)
                    })
                    row.internalCodes = tmp
                })*/
                setRows(data)
                setLoading(false)
            }).catch(err => console.log("Error getting total standards count ",err))
        }).catch(err => console.log("Error getting standards rows ",err))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var limit = parseInt(event.target.value, 10)
        getCurrentRows(limit, 0, searchValue, internalCodesFull)
        setRowsPerPageLimit(limit);
        setCurrentPageOffset(0)
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        getCurrentRows(rowsPerPageLimit, newPage, searchValue, internalCodesFull)
        setCurrentPageOffset(newPage)
    };

    const handleSearch = (value) => {
        getCurrentRows(rowsPerPageLimit, currentPageOffset, value, internalCodesFull)
        setSearchValue(value)
    }


    useEffect(() => {
        findAllInternalCodes(null,null).then(icodes => {
            setInternalCodesFull(icodes)
            getCurrentRows(rowsPerPageLimit,currentPageOffset,searchValue,icodes)
        }).catch(err => console.log("Error getting internalcodes ",err))
    },[]);

    if(loading){
        return (
            <div style={{display: 'flex', justifyContent: 'center',marginTop: 200}}><CircularProgress color="secondary"/></div>
        )
    }else{
    return (
        <Paper className={classes.root} style={props.style}>
            <div className={classes.filterContainer}>
                <SearchStyled text={t("SEARCHBYCODE")} value={searchValue} handleValueFunction={handleSearch}/>
                <ButtonStyled
                    text={t("CREATENEW")}
                    onClick={() => navigate("/standards/create")}/>
                <div className={classes.flexBreak}/>
                {/*<AutocompleteStyled label={t("ICODESFILTER")} value={icodesFilter} multiple={true}
                                    options={internalCodesFull!=null ? internalCodesFull.map((option) => option.name) : []}
    handleValueFunction={setIcodesFilter} style={{width: "40%", minWidth: 300}}/>*/}
            </div>
            <StandardsTable showCheckboxes={false} showInternalCodes={true} rows={rows} rowsPerPage={rowsPerPageLimit} handleChangeRowsPerPage={handleChangeRowsPerPage} count={totalStandardsCount} page={currentPageOffset} handleChangePage={handleChangePage}/>
        </Paper>
    )}
}