import React, {useEffect, useState} from 'react'
import { TableRow, TableCell, Checkbox } from "@material-ui/core";
import {navigate} from "gatsby";

import { useStyles } from "../../styles/TableStyles";
import { Harmonized, InternalCodeDto } from '../../api/StandardApi';

import { useTranslation } from 'react-i18next';

interface StandardPreviewProps {
    id: number,
    name: string,
    standard: string,
    harmonized: Harmonized,
    internalCodes?: InternalCodeDto[],
    included?:boolean,
    handleIncluded?:any,
    linkInNewWindow?:boolean,
    showUrl?:boolean,
    url?:string,
}

export default function StandardPreview(props: StandardPreviewProps) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [checked, setChecked] = useState<boolean>(props.included);
    useEffect(()=>{
        setChecked(props.included)
    },[props.included])

    return (
        <TableRow className={classes.childRowWithLink}  hover>
            <TableCell style={checked==false ? {opacity:"40%"} : {}} onClick={()=>props.linkInNewWindow==true ? window.open("/standards/"+props.id, "_blank") : navigate("/standards/"+props.id)}  key={props.id+"standard"} className={classes.cellWithBackground} align="center">
                {props.standard}
            </TableCell>
            <TableCell style={checked==false ? {opacity:"40%"} : {}} onClick={()=>props.linkInNewWindow==true ? window.open("/standards/"+props.id, "_blank") : navigate("/standards/"+props.id)}  key={props.id+"name"} className={classes.cellWithBorder} align="left">
                {props.name}
            </TableCell>
            {props.internalCodes!=null &&  
            <TableCell onClick={()=>props.linkInNewWindow==true ? window.open("/standards/"+props.id, "_blank") : navigate("/standards/"+props.id)}  key={props.id+"internalCodes"} className={classes.cellWithBorder} align="left">
                {props.internalCodes.map(icode=>icode.name).join(", ")}
            </TableCell>}
            <TableCell style={checked==false ? {opacity:"40%"} : {}} onClick={()=>props.linkInNewWindow==true ? window.open("/standards/"+props.id, "_blank") : navigate("/standards/"+props.id)}  key={props.id+"harmonized"} className={classes.cellWithBorder} align="left">
                {props.harmonized==Harmonized.YES ? t("YES") : t("NO")}
            </TableCell>
            {props.showUrl==true &&  
            <TableCell onClick={()=>window.open(props.url, "_blank")}  key={props.id+"url"} className={classes.cellWithBorder} align="left">
                <a href={props.url} target="_blank">{props.url.slice(0,18)}..</a>
            </TableCell>}
            {props.included!=null &&  
             <TableCell key={props.id+"included"} className={classes.cellWithBorder} align="left">
                <Checkbox
                    checked={checked}
                    value={props.included}
                    onChange={(event)=>{
                        props.handleIncluded(event.target.checked)
                        setChecked(event.target.checked)
                        }}
                    color="primary"
                    inputProps={{ 'aria-label': 'include checkbox' }}
                />
            </TableCell>}
        </TableRow>
    );
}