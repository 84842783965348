import APIKit from './Base'

export interface StandardDto{
    id:number,
    name?:string,
    code?:string,
    annotation?:string,
    catalogNumber?:number,
    url?:string,
    englishName?:string,
    validity?:Date,
    harmonized?:Harmonized,
    internalCodes?:InternalCodeDto[],

    included?: boolean;
}

export enum Harmonized{
 YES="YES", NO="NO"
}
export interface StandardCreateDto{
    name?:string,
    code?:string,
    annotation?:string,
    catalogNumber?:number,
    internalCodes?:number[]
}

export interface StandardDtoPair{
    first?: StandardDto[],
    second?: StandardDto[],
}

export interface InternalCodeDto{
    id?:number,
    name?:string,
    type?:string
}

export const countStandards = (name, catalogNumber, code) =>{
    return APIKit.get("/standard/count", { params: { name: name, catalogNumber: catalogNumber, code: code } })
        .then<number>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const findAllStandards = (dtoSwitcher, limit, offset, name, catalogNumber, code) => {
    return APIKit.get("/standard",{ params: { dtoSwitcher: dtoSwitcher, limit: limit, offset: offset, name: name, catalogNumber: catalogNumber, code:code}})
        .then<StandardDto[]>(response => {
            return response.data})
        .catch(err => {
            throw(err)
        })
}

export const findByInternalCodes = (dtoSwitcher, fullText, internalCodes) => {
    return APIKit.get("/standard/find",{ params: { dtoSwitcher: dtoSwitcher, fullText: fullText, internalCodes: internalCodes}})
    .then<StandardDtoPair>(response => {
        return response.data})
    .catch(err => {
        throw(err)
    })
}

export const findAllInternalCodes = (byName, byType) =>{
    return APIKit.get("/standard/internal-code", { params: { name: byName, type: byType } })
        .then<InternalCodeDto[]>(response => {
            return response.data})
        .catch(err => {
            throw(err)
        })
}

export const findStandardById = (id) => {
    return APIKit.get("/standard/"+id)
        .then<StandardDto>(response => {
            return response.data})
        .catch(err => {
            throw(err)
        })
}

export const createStandard = (data: StandardCreateDto) => {
    return APIKit.post("/standard",data)
        .then<StandardDto>((response) => {
            return response.data
        })
        .catch((err) => {
            throw(err)
        })
}

export const updateStandard = (id, data: StandardCreateDto) => {
    return APIKit.put("/standard/"+id,data)
        .then<StandardDto>((response) => {
            return response.data
        })
        .catch((err) => {
            throw(err)
        })
}

export const deleteStandard = (id) => {
    return APIKit.delete("/standard/"+id)
        .then((response) => {
            return response.status==204
        })
        .catch((err) => {
            throw(err)
        })
}
